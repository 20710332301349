import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './core/auth.service';
import { DeviceService } from './core/device.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Suvinil';
  isIframe = false;
  loginDisplay$: Observable<boolean> = this.authService.loginDisplayObs;
  isMobile: boolean;
  
  constructor(private readonly authService: AuthService, private readonly deviceService: DeviceService) {
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
  }
}
