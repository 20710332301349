import {Component, EventEmitter, Input, Output} from '@angular/core';

export enum FrequencyMonth {
  ALL = 'Todos',
  Q = 'Quarter',
  M = 'Mes',
}
@Component({
  selector: 'container-frequency',
  templateUrl: './container-frequency.component.html',
  styleUrl: './container-frequency.component.scss',
})
export class ContainerFrequencyComponent {
  @Output() frequencySelected: EventEmitter<FrequencyMonth> = new EventEmitter<FrequencyMonth>();
  @Input() titleChartBar!: string;
  @Input() selectedFrequency: FrequencyMonth = FrequencyMonth.ALL;

  frequencies: FrequencyMonth[] = [FrequencyMonth.ALL, FrequencyMonth.Q, FrequencyMonth.M];
  clickButton(frequency: FrequencyMonth) {
    this.selectedFrequency = frequency;
    this.frequencySelected.emit(frequency);
  }
}
