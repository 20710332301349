import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ChartData, ChartMultiLineData, typeChart,} from '../../charts/chart.model';
import {FrequencyMonth} from '../container-frequency/container-frequency.component';

@Component({
  selector: 'container-bar',
  templateUrl: './container-bar.component.html',
  styleUrl: './container-bar.component.scss',
})
export class ContainerBarComponent implements OnInit {
  @Input() wrapperChartData!: any;
  @Input() lineChartData!: any;
  @Input() colorChartBar!: string;
  @Input() titleChartBar!: string;
  @Input() principalPercentageChartBar!: string;
  @Input() secondaryPercentageChartBar!: string;
  @Input() chartLineData!:
    | ChartData[]
    | ChartMultiLineData[];
  @Input() frequencyChartBar: FrequencyMonth = FrequencyMonth.ALL;
  @Input() chartHeightPx!: string;
  @Input() showTitlePercentage: boolean = true;
  @Input() showFrequency: boolean = true;
  @Input() showLegend: boolean = false;
  @Input() topTooltipBar: boolean = true;
  @Input() percentageAxisY: boolean = false;
  @Input() percentageAxisY1: boolean = false;
  @Input() xScale: string = '';
  @Input() yScale: string = '';
  @Input() y1Scale: string = '';
  @Input() yAxisMultiplier: number = 1;
  @Input() horizontalBar: boolean = false;

  @Output() frequencySelected: EventEmitter<FrequencyMonth> =
    new EventEmitter<FrequencyMonth>();

  typeChart: typeChart = 'line';
  ngOnInit(): void {
    this.frequencyChartBar = FrequencyMonth.ALL;
  }
  frequencySelectedButton(frequency: FrequencyMonth) {
    this.frequencyChartBar = frequency;
  }
}
